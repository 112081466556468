<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Cuotas')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" v-model:expandedRows="expandedRows" dataKey="id"
					@rowExpand="onRowExpand" @rowCollapse="onRowCollapse" responsiveLayout="scroll"
					:paginator="true" :rows="25" >
					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="cliente" :header="$t('Cliente') + '#'" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							<div>{{slotProps.data.cliente}}</div>                              
						</template>
					</Column>
					<Column field="nombre" :header="$t('Nombre Completo')">
						<template #body="slotProps">
							<div>{{slotProps.data.nombre}}</div>     
						</template>
					</Column>  
					<Column field="monto"  :header="$t('Deudas')">
						<template #body="slotProps">
							<div>{{moneda(slotProps.data.monto)}}</div>     
						</template>
					</Column>
					<template #expansion="slotProps">
						<div class="orders-subtable">
							<h6>{{slotProps.data.nombre}}, {{$t('Deudas')+': '+slotProps.data.deudas.length}}</h6>
							<DataTable :value="slotProps.data.deudas" responsiveLayout="scroll">
								<Column :header="$t('Póliza') + '#'" field="nombre" :sortable="true" headerStyle="width: 200px">	
									<template #body="{data}">
										{{data.codigo}}
									</template>
								</Column>
								<Column :header="$t('Cliente') + '#'" field="cliente" :sortable="true" headerStyle="width: 120px">	
									<template #body="{data}">
										{{data.cliente}}
									</template>
								</Column>
								<Column field="fecha" :header="$t('Fecha')" :sortable="true" headerStyle="width: 100px"></Column>
								<Column :header="$t('Monto')" field="monto" :sortable="true" headerStyle="width: 180px">	
									<template #body="{data}">
										{{moneda(data.monto)}}
									</template>
								</Column>
								<Column :header="$t('Vencimiento')" field="vencimiento" :sortable="true" headerStyle="width: 100px">	
									<template #body="{data}">
										{{data.vencimiento}}
									</template>
								</Column>
							</DataTable>
						</div>
					</template>     
                    <template #footer footerStyle="text-align:right">
                        <Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="products.length>0"/>
                    </template>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				product: {},
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				expandedRows: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				const Consulta = new API('Reportes');
				Consulta.Ini('Report6').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					if (result.consult.usuarios){
						this.products = result.consult.usuarios;
					}
					this.$store.commit('Loading');
				}); 
			},
			VerXLS(){
				const xls = new XLS();
				xls.Reporte6(this.products,this.$store.state.currency);
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},

			expandAll() {
				this.expandedRows = this.products.filter(p => p.id);
				this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
			},
			collapseAll() {
				this.expandedRows = null;
				this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
			},

			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
